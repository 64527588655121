import React from "react";
import icon1 from "../assets/Icon 8.png";
import icon2 from "../assets/Icon 7.png";
import icon3 from "../assets/Icon 6.png";

import { useTranslation } from "react-i18next";

const FeaturesSection = () => {
	const { t } = useTranslation();

	return (
		<div className="FeaturesSection">
			<div className="container">
				<div className="block">
					<img src={icon1} alt="" />
					<h6>{t("features.contact_us.title")}</h6>
					<p>{t("features.contact_us.description")}</p>
				</div>
				<div className="block">
					<img src={icon2} alt="" />
					<h6>{t("features.professional_designs.title")}</h6>
					<p>{t("features.professional_designs.description")}</p>
				</div>
				<div className="block">
					<img src={icon3} alt="" />
					<h6>{t("features.flexibility.title")}</h6>
					<p>{t("features.flexibility.description")}</p>
				</div>
			</div>
		</div>
	);
};

export default FeaturesSection;
