import React, { useState, useEffect } from "react";
import videoBg from "../assets/215500.mp4";
// import pencil_file from "../assets/pepicons-pencil_file.png";
import { FaWhatsapp, FaArrowUp } from "react-icons/fa";

import { useTranslation } from "react-i18next";

const Home = () => {
	const [showArrow, setShowArrow] = useState(false); // State to control arrow visibility
	const { t } = useTranslation();

	const services = [
		t("home.design_develop_integrated_systems"),
		t("home.android_apps"),
		t("home.ios_apps"),
		t("home.integration_with_payment_gatways"),
		t("home.design_develop_website_apps"),
		t("home.design_develop_ui_ux"),
		t("home.digital_marketing"),
		t("home.brochures"),
		t("home.logos"),
		t("home.design_brand_identity"),
		t("home.searche_engine_optimization"),
		t("home.data_analysis_and_digital_performance"),
		t("home.social_media_content_designs"),
		t("home.publications"),
		t("home.presentation_design"),
		t("home.motion_graphics"),
		t("home.advertising_campaigns_management"),
	];

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 300) {
				setShowArrow(true);
			} else {
				setShowArrow(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<section id="home">
			<div className="video-container">
				<video autoPlay muted loop playsInline>
					<source src={videoBg} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				<div className="overlay"></div>

				<div className="content">
					<div className="header1">{t("home.to_future")}</div>
					<div className="title1 title">
						<span className="BIT-TECH"> BIT TECH</span>
					</div>
					<div className="title2 title">
						<span className="BIT-TECH">BIT TECH</span>
					</div>
					<p className="header2">
						{t("home.passionate_part1")}
						<br className="break" />
						{t("home.passionate_part2")}
					</p>
					{/* <div className="pencil_file_container">
						<button className="pencil_file">
							<img src={pencil_file} alt="pencil_file" />
							<span>{t("home.our_profile")}</span>
						</button>
					</div> */}
					<div className="our-Services">
						<div className="header">{t("home.we_can_help_you_with")}</div>
						<ul>
							{services.map((service, index) => (
								<li className="home_tags" key={index}>
									{service}
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>

			{/* Floating icons */}
			<div className="floating-icons">
				{/* Back to top arrow */}
				{showArrow && (
					<button onClick={scrollToTop} className="back-to-top">
						<FaArrowUp size={40} />
					</button>
				)}
				{/* WhatsApp icon */}
				<a
					href="https://wa.me/966590478587"
					target="_blank"
					rel="noopener noreferrer"
					className="whatsapp-icon"
				>
					<FaWhatsapp size={40} />
				</a>
			</div>
		</section>
	);
};

export default Home;
