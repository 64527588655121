import React from "react";
import Home from "./components/Home";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import About from "./components/About";
import Orientations from "./components/Orientations";
import CustomerJourney from "./components/CustomerJourney";
// import OurBusiness from "./components/OurBusiness";
import FeaturesSection from "./components/FeaturesSection ";
import Footer from "./components/Footer";
import ExploreOurServices from "./components/ExploreOurServices";

const App = () => {
	return (
		<>
			<Navbar />
			<Home />
			<Services />
			<About />
			<Orientations />
			<CustomerJourney />
			<ExploreOurServices />
			{/* <OurBusiness/> */}
			<FeaturesSection />
			<Footer />
		</>
	);
};

export default App;
