import React from "react";
import pic from "../assets/Pic.png";
import icon1 from "../assets/Icon.png";
import icon2 from "../assets/Icon 2.png";
import icon3 from "../assets/Icon 3.png";
import icon4 from "../assets/Icon 4.png";

import { useTranslation } from "react-i18next";

const CustomerJourney = () => {
	const { t } = useTranslation();

	return (
		<section className="CustomerJourney">
			<div className="CustomerJourney-container">
				<div className="container-r">
					<h2>{t("customer_journey.title")}</h2>
					<p>{t("customer_journey.description")}</p>
					<div className="CustomerJourney-img">
						<img src={pic} alt="CustomerJourney" />
					</div>
				</div>

				<div className="content-container">
					<div className="content">
						<div className="icon">
							<img src={icon1} alt="icon" />
						</div>
						<div className="container2">
							<h5>{t("customer_journey.exploration_title")}</h5>
							<p>{t("customer_journey.exploration_description")}</p>
						</div>
					</div>
					<div className="content">
						<div className="icon">
							<img src={icon2} alt="icon" />
						</div>
						<div className="container2">
							<h5>{t("customer_journey.strategy_title")}</h5>
							<p>{t("customer_journey.strategy_description")}</p>
						</div>
					</div>
					<div className="content">
						<div className="icon">
							<img src={icon3} alt="icon" />
						</div>
						<div className="container2">
							<h5>{t("customer_journey.implementation_title")}</h5>
							<p>{t("customer_journey.implementation_description")}</p>
						</div>
					</div>
					<div className="content">
						<div className="icon">
							<img src={icon4} alt="icon" />
						</div>
						<div className="container2">
							<h5>{t("customer_journey.testing_title")}</h5>
							<p>{t("customer_journey.testing_description")}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CustomerJourney;
