import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/LOGO.png";
import Menu from "../assets/Menu.png";

import { useTranslation } from "react-i18next";
import { supportedLngs } from "../i18n/config.ts";

const Navbar = () => {
	const [scrolling, setScrolling] = useState(false);
	const [visible, setVisible] = useState(false);
	const [activeSection, setActiveSection] = useState("");
	const navRef = useRef(null);
	const buttonRef = useRef(null); // Ref for the button

	const { i18n, t } = useTranslation();

	useEffect(() => {
		const dir = i18n.dir(i18n.language);
		document.documentElement.dir = dir;
	}, [i18n, i18n.language]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			// Check if the click is outside the mobile nav and button
			if (
				navRef.current &&
				!navRef.current.contains(event.target) &&
				buttonRef.current &&
				!buttonRef.current.contains(event.target)
			) {
				setVisible(false);
			}
		};

		if (visible) {
			document.addEventListener("mousedown", handleClickOutside);
			// Prevent body scroll when the mobile menu is open
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.body.style.overflow = "auto";
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [visible]);

	const scrollToSection = (id) => {
		const navbarHeight = 90; // Adjust to your navbar height
		const section = document.getElementById(id);

		if (section) {
			const sectionTop = section.offsetTop - navbarHeight;
			window.scrollTo({
				top: sectionTop,
				behavior: "smooth",
			});
			setActiveSection(id);
		}
		setVisible(false); // Hide the mobile menu after scrolling
	};

	const handleScroll = () => {
		const sections = ["home", "aboutUs", "services", "business"];
		let currentSection = "";

		sections.forEach((sectionId) => {
			const section = document.getElementById(sectionId);
			if (section) {
				const rect = section.getBoundingClientRect();
				if (
					rect.top <= window.innerHeight / 2 &&
					rect.bottom >= window.innerHeight / 2
				) {
					currentSection = sectionId;
				}
			}
		});

		setActiveSection(currentSection);

		if (window.scrollY > 50) {
			setScrolling(true);
		} else {
			setScrolling(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<header>
			<nav
				className={`nav-container ${scrolling ? "bg-dark" : "bg-transparent"}`}
			>
				<div className="nav-logo">
					<img src={logo} alt="logo" onClick={() => scrollToSection("home")} />
				</div>

				<div className="Menu" ref={buttonRef}>
					<img
						src={Menu}
						alt="Menu-icon"
						onClick={() => setVisible((prev) => !prev)} // Toggle visibility on button click
					/>
				</div>

				<div className="nav-links desktop">
					<ul>
						<li
							className={activeSection === "home" ? "active" : ""}
							onClick={() => scrollToSection("home")}
						>
							{t("navbar.home")}
						</li>
						<li
							className={activeSection === "aboutUs" ? "active" : ""}
							onClick={() => scrollToSection("aboutUs")}
						>
							{t("navbar.about_us")}
						</li>
						<li
							className={activeSection === "services" ? "active" : ""}
							onClick={() => scrollToSection("services")}
						>
							{t("navbar.our_services")}
						</li>
						{/* <li
							className={activeSection === "business" ? "active" : ""}
							onClick={() => scrollToSection("business")}
						>
							أعمالنا
						</li> */}
					</ul>
				</div>

				<div className="contact-us">
					<div className="language-list">
						<select
							value={i18n.resolvedLanguage}
							onChange={(e) => i18n.changeLanguage(e.target.value)}
						>
							{Object.entries(supportedLngs).map(([code, name]) => (
								<option value={code} key={code}>
									{name}
								</option>
							))}
						</select>
					</div>
					<button
						onClick={() => window.open("https://wa.me/966590478587", "_blank")}
					>
						{t("footer.contact_us")}
					</button>
				</div>
			</nav>

			{visible && (
				<div ref={navRef} className="nav-links mobile blur">
					<ul>
						<li
							className={activeSection === "home" ? "active" : ""}
							onClick={() => scrollToSection("home")}
						>
							{t("navbar.home")}
						</li>
						<li
							className={activeSection === "aboutUs" ? "active" : ""}
							onClick={() => scrollToSection("aboutUs")}
						>
							{t("navbar.about_us")}
						</li>
						<li
							className={activeSection === "services" ? "active" : ""}
							onClick={() => scrollToSection("services")}
						>
							{t("navbar.our_services")}
						</li>
						<li>
							<div className="language-list">
								<select
									value={i18n.resolvedLanguage}
									onChange={(e) => i18n.changeLanguage(e.target.value)}
								>
									{Object.entries(supportedLngs).map(([code, name]) => (
										<option value={code} key={code}>
											{name}
										</option>
									))}
								</select>
							</div>
						</li>
						{/* <li
							className={activeSection === "business" ? "active" : ""}
							onClick={() => scrollToSection("business")}
						>
							أعمالنا
						</li> */}
					</ul>
				</div>
			)}
		</header>
	);
};

export default Navbar;
