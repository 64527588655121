import React from "react";
import logo from "../assets/logo2.png";
import Vector1 from "../assets/Frame 1.png";
import email from "../assets/dashicons_email.png";
import frame from "../assets/Frame 3.png";
// import linkedin from "../assets/Linkedin.png";
import { useTranslation } from "react-i18next";

// import twitter from "../assets/pic4.png";
// import Instagram from "../assets/pic2.png";
// import Facebook from "../assets/pic1.png";

const Footer = () => {
	const { t } = useTranslation();

	const scrollToSection = (id) => {
		const section = document.getElementById(id);
		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	};
	return (
		<footer>
			<div className="header">
				<div>{t("footer.ready_to_work_with_us")}</div>
				<div>
					<button
						onClick={() => window.open("https://wa.me/966590478587", "_blank")}
					>
						{t("footer.contact_us")}
					</button>
				</div>
			</div>
			<div className="containers">
				<div className="right-container">
					<div>
						<img src={logo} alt="" />
					</div>
					<p>
						{t("footer.paragraph")}
						<br /> <br />
						{t("footer.certified_by")}
					</p>
				</div>
				<div className="left-container">
					<div className="container1">
						<h6>{t("footer.quick_links")}</h6>
						<ul>
							<li onClick={() => scrollToSection("home")}>
								{t("navbar.home")}
							</li>
							<li onClick={() => scrollToSection("aboutUs")}>
								{t("navbar.about_us")}
							</li>
							<li onClick={() => scrollToSection("services")}>
								{t("navbar.our_services")}
							</li>
							{/* <li onClick={() => scrollToSection("business")}>أعمالنا </li> */}
						</ul>
					</div>
					<div>
						<div>
							<h6>{t("footer.contact_us")}</h6>
							<ul>
								<li>
									<img src={Vector1} alt="email" />
									<span style={{ direction: `ltr` }}>{"+966590478587"}</span>
								</li>
								<li>
									<img src={email} alt="email" /> <span>info@bit-tech.sa</span>
								</li>
								<li>
									<img src={frame} alt="email" />{" "}
									<span>Riyadh, Kingdom of Saudi Arabia </span>
								</li>
							</ul>
						</div>
						<div className="social">
							{/* <div className="img">
								<img src={twitter} alt="twitter" />
							</div> */}
							{/* <a
								href="https://linkedin.com/company/bit-tech-sa"
								className="img"
							>
								<img src={linkedin} alt="linkedin" />
							</a> */}
							{/* <div className="img">
								<img src={Instagram} alt="Instagram" />
							</div>
							<div className="img">
								<img src={Facebook} alt="Facebook" />
							</div> */}
						</div>
					</div>
				</div>
			</div>
			<div className="line-container">
				<div className="line"></div>
				<p> 2024 © bit-tech.sa - All Right Reserved</p>
			</div>
		</footer>
	);
};

export default Footer;
