import React, { useEffect, useRef, useState } from "react";
import eye from "../assets/Eye.png";
import message from "../assets/message-square-lines-alt-svgrepo-com 1.png";
import target from "../assets/Target.png";

import { useTranslation } from "react-i18next";

const Orientations = () => {
	const sectionRef = useRef(null);
	const [inView, setInView] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setInView(true);
				}
			},
			{
				threshold: 0.3, // Adjust as needed
			},
		);

		if (sectionRef.current) {
			observer.observe(sectionRef.current);
		}

		return () => {
			if (sectionRef.current) {
				observer.unobserve(sectionRef.current);
			}
		};
	}, []);

	return (
		<section className="Orientations" ref={sectionRef}>
			<div className="Orientations-container">
				<div>
					<h2>{t("orientations.title")}</h2>
					<div className="content-container">
						<div className="content">
							<div className="icon">
								<img src={eye} alt="eye-icon" />
							</div>
							<div className="container2">
								<h5>{t("orientations.vision.title")}</h5>
								<p>{t("orientations.vision.description")}</p>
							</div>
						</div>
						<div className="content">
							<div className="icon">
								<img src={message} alt="message-icon" />
							</div>
							<div className="container2">
								<h5>{t("orientations.message.title")}</h5>
								<p>{t("orientations.message.description")}</p>
							</div>
						</div>
						<div className="content">
							<div className="icon">
								<img src={target} alt="target-icon" />
							</div>
							<div className="container2">
								<h5>{t("orientations.mission.title")}</h5>
								<p>{t("orientations.mission.description")}</p>
							</div>
						</div>
					</div>
				</div>
				<div className={`image-stack ${inView ? "in-view" : ""}`}>
					<div className="image-container img1"></div>
					<div className="image-container img2"></div>
					<div className="image-container img3"></div>
				</div>
			</div>
		</section>
	);
};

export default Orientations;
