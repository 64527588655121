import React, { useEffect, useState } from "react";
import icon from "../assets/Group5.png";
// import { OurServices } from "../data";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ExploreOurServices.css";

import { useTranslation } from "react-i18next";

export default function ExploreOurServices() {
	const [value, setValue] = useState("website_design_development");
	const [isFading, setIsFading] = useState(false); // For smooth transition

	const { t } = useTranslation();

	const services = [
		"website_design_development",
		"application_design_development",
		"project_solution_programming",
		"hosting",
		"identity_design",
		"digital_marketing",
		"motion_graphics",
	];

	const OurServices = {
		website_design_development: {
			title: t("services.website_design_development.title"),
			subTitle: t("services.website_design_development.subTitle"),
			des: t("services.website_design_development.description"),
			list: [
				t("services.website_design_development.point_1"),
				t("services.website_design_development.point_2"),
				t("services.website_design_development.point_3"),
				t("services.website_design_development.point_4"),
			],
		},
		application_design_development: {
			title: t("services.application_design_development.title"),
			subTitle: t("services.application_design_development.subTitle"),
			des: t("services.application_design_development.description"),
			list: [
				t("services.application_design_development.point_1"),
				t("services.application_design_development.point_2"),
				t("services.application_design_development.point_3"),
				t("services.application_design_development.point_4"),
			],
		},

		project_solution_programming: {
			title: t("services.project_solution_programming.title"),
			subTitle: t("services.project_solution_programming.subTitle"),
			des: t("services.project_solution_programming.description"),
			list: [
				t("services.project_solution_programming.point_1"),
				t("services.project_solution_programming.point_2"),
				t("services.project_solution_programming.point_3"),
				t("services.project_solution_programming.point_4"),
			],
		},
		hosting: {
			title: t("services.hosting.title"),
			subTitle: t("services.hosting.subTitle"),
			des: t("services.hosting.description"),
			list: [
				t("services.hosting.point_1"),
				t("services.hosting.point_2"),
				t("services.hosting.point_3"),
				t("services.hosting.point_4"),
			],
		},

		digital_marketing: {
			title: t("services.digital_marketing.title"),
			subTitle: t("services.digital_marketing.subTitle"),
			des: t("services.digital_marketing.description"),
			list: [
				t("services.digital_marketing.point_1"),
				t("services.digital_marketing.point_2"),
				t("services.digital_marketing.point_3"),
				t("services.digital_marketing.point_4"),
			],
		},
		identity_design: {
			title: t("services.identity_design.title"),
			subTitle: t("services.identity_design.subTitle"),
			des: t("services.identity_design.description"),
			list: [
				t("services.identity_design.point_1"),
				t("services.identity_design.point_2"),
				t("services.identity_design.point_3"),
				t("services.identity_design.point_4"),
			],
		},
		motion_graphics: {
			title: t("services.motion_graphics.title"),
			subTitle: t("services.motion_graphics.subTitle"),
			des: t("services.motion_graphics.description"),
			list: [
				t("services.motion_graphics.point_1"),
				t("services.motion_graphics.point_2"),
				t("services.motion_graphics.point_3"),
				t("services.motion_graphics.point_4"),
			],
		},
	};

	const serviceKeys = Object.keys(OurServices);
	const selectedService = OurServices[value];

	// Handle smooth transition when value changes
	useEffect(() => {
		if (isFading) {
			const timeout = setTimeout(() => {
				setIsFading(false); // Reset fading after a timeout
			}, 300); // Match this time with the CSS animation duration
			return () => clearTimeout(timeout);
		}
	}, [isFading]);

	const handleChange = (service) => {
		setIsFading(true); // Start fading out
		setTimeout(() => {
			setValue(service); // Update value after fade out
		}, 300); // Match this time with the CSS animation duration
	};

	// Slider settings for mobile view
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		afterChange: (index) => {
			handleChange(serviceKeys[index]);
		},
	};

	return (
		<section className="ExploreOurServices" id="services">
			<div className="header">
				<h2>{t("services.title")}</h2>
				<p>{t("services.description")}</p>
			</div>
			<div className="container">
				<div className="menu">
					{/* Mobile slider */}
					<div className="menuu slider-mobile">
						<Slider {...settings}>
							{services.map((service, index) => (
								<div
									key={index}
									className={`menu-item ${service ? "active" : ""}`}
								>
									<span className={`fade-value ${isFading ? "fading" : ""}`}>
										{value}
									</span>
								</div>
							))}
						</Slider>
					</div>

					{/* Web view menu */}
					<ul className="menu-web">
						{serviceKeys.map((service, index) => (
							<li
								key={index}
								className={value === service ? "active" : ""}
								onClick={() => handleChange(service)}
								onFocus={() => handleChange(service)}
								tabIndex={0} // Make list items focusable for keyboard users
							>
								<span className={`fade-value ${isFading ? "fading" : ""}`}>
									{OurServices[service].title}
								</span>
							</li>
						))}
					</ul>
				</div>
				<div className={`content ${isFading ? "fading" : ""}`}>
					{selectedService && (
						<>
							<h3>{selectedService.title}</h3>
							<h5>{selectedService.subTitle}</h5>
							<p>{selectedService.des}</p>
							<div>
								<ul>
									{selectedService.list.map((item, index) => (
										<li key={index}>
											<img src={icon} alt="icon" />
											<span>{item}</span>
										</li>
									))}
								</ul>
							</div>
						</>
					)}
				</div>
			</div>
		</section>
	);
}
