import React, { useEffect, useState } from "react";
// import { data } from "../data";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

import img3 from "../assets/Frame.png";
import img2 from "../assets/Frame 2.png";
import img1 from "../assets/frame3.png";
import img4 from "../assets/Frame4.png";
import img5 from "../assets/Frame5.png";
import img6 from "../assets/frame6.png";
// import img from "./assets/image.png";

const Services = () => {
	const { i18n, t } = useTranslation();

	const [cards, setCards] = useState([]);

	let data = [
		{
			id: 2,
			title: t("we_offer_you.website_design_development.title"),
			content: t("we_offer_you.website_design_development.description"),
			img: img2,
		},
		{
			id: 3,
			title: t("we_offer_you.mobile_application_development.title"),
			content: t("we_offer_you.mobile_application_development.description"),
			img: img3,
		},
		{
			id: 4,
			title: t("we_offer_you.digital_marketing.title"),
			content: t("we_offer_you.digital_marketing.description"),
			img: img4,
		},
		{
			id: 1,
			title: t("we_offer_you.development_platform_solutions_services.title"),
			content: t(
				"we_offer_you.development_platform_solutions_services.description",
			),
			img: img1,
		},
		{
			id: 5,
			title: t("we_offer_you.graphic_design_brand_identity.title"),
			content: t("we_offer_you.graphic_design_brand_identity.description"),
			img: img5,
		},
		{
			title: t("we_offer_you.videos_motion_graphics.title"),
			content: t("we_offer_you.videos_motion_graphics.description"),
			img: img6,
		},
	];

	useEffect(() => {
		setCards(data);
	}, [i18n, i18n.language]);

	const scrollToSection = (id) => {
		const section = document.getElementById(id);
		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div className="Services">
			<div className="Services-container">
				<h2 className="header">{t("we_offer_you.title")}</h2>
				<p className="content">{t("we_offer_you.description")}</p>
				<div className="cards">
					<AnimatePresence>
						{cards.map((item, index) => (
							<AnimatedCard key={index} item={item} index={index} />
						))}
					</AnimatePresence>
				</div>
				<div className="more-services">
					<button onClick={() => scrollToSection("services")}>
						{t("we_offer_you.view_all_services")}
					</button>
				</div>
			</div>
		</div>
	);
};

const AnimatedCard = ({ item, index }) => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.2,
	});

	const controls = useAnimation();

	useEffect(() => {
		if (inView) {
			controls.start("visible");
		}
	}, [controls, inView]);

	const cardVariants = {
		hidden: { opacity: 0, scale: 0.8 },
		visible: {
			opacity: 1,
			scale: 1,
			transition: { duration: 0.6, delay: index * 0.3 },
		},
	};

	const defaultImage = "path/to/default-image.jpg";

	return (
		<motion.div
			className="card"
			ref={ref}
			initial="hidden"
			animate={controls}
			variants={cardVariants}
		>
			<img src={item?.img || defaultImage} alt={item?.title || "frame"} />
			<h4>{item?.title}</h4>
			<p>{item?.content}</p>
		</motion.div>
	);
};

export default Services;
