import React from "react";
import pic1 from "../assets/pic 1.png";
import pic2 from "../assets/pic 2.png";
import pic3 from "../assets/pic 3.png";

import { useTranslation } from "react-i18next";

const About = () => {
	const { t } = useTranslation();

	return (
		<div className="about" id="aboutUs">
			<div className="about-container">
				<h2 className="header">{t("about_us.title")}</h2>
				<div className="content">
					<div className="img-container">
						<div>
							<img src={pic1} alt="" className="img1" />
						</div>
						<div className="img-container2">
							<div>
								<img src={pic2} alt="" className="img2" />
							</div>
							<div>
								<img src={pic3} alt="" className="img3" />
							</div>
						</div>
					</div>
					<p>
						{t("about_us.description_part_1")}
						<br />
						<br />
						{t("about_us.description_part_2")}
						<br />
						<br />
						{t("about_us.description_part_3")}
					</p>
				</div>
			</div>
		</div>
	);
};

export default About;
